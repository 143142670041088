import React from 'react'
import { Layout } from '../components/Layout'
import { ParagraphLarge } from 'baseui/typography'
import { Heading } from '../components/Heading'
import { HeadingLevel } from 'baseui/heading'
import { Cell, Grid } from 'baseui/layout-grid'
import { Input } from '../components/Input'
import { FormControl } from 'baseui/form-control'
import { navigate } from 'gatsby'
import { Button } from '../components/Button'
import { passwordResetRequest, PasswordResetRequestError } from '../api/user'

interface InputOpts {
  error?: boolean
}

const PasswordResetRequestPage = () => {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState<PasswordResetRequestError | null>(null)
  const [loading, setLoading] = React.useState(false)
  const inputOpts: InputOpts = { error: false }
  if (error) {
    inputOpts.error = true
  }

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)

    const successFunction = () => {
      setLoading(false)
      navigate('/reset-password-confirmation')
    }
    const failedFunction = (reasons: PasswordResetRequestError) => {
      setLoading(false)
      setError(reasons)
    }
    passwordResetRequest(email, successFunction, failedFunction)
  }

  return (
    <Grid>
      <Cell span={[4, 6]}>
        <HeadingLevel>
          <Heading> Reset Password </Heading>
          <ParagraphLarge> Please enter the email you signed up to OHShelp with </ParagraphLarge>
          <form onSubmit={submitForm}>
            <FormControl label="Email" caption={() => ''} error={error ? error.email : null}>
              <Input
                id="password-reset-email"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
                type="email"
                clearable={true}
                {...inputOpts}
              />
            </FormControl>
            <Button
              overrides={{ BaseButton: { style: { marginTop: '14px' } } }}
              kind="secondary"
              isLoading={loading}
              onClick={submitForm}
              data-testid={!loading ? 'submit-button' : 'submit-button-loading'}
            >
              Send
            </Button>
          </form>
        </HeadingLevel>
      </Cell>
    </Grid>
  )
}

const LayoutPasswordResetRequestPage = () => {
  return (
    <Layout showNav={false} searchBarAvailable={false} backButton={false}>
      <PasswordResetRequestPage />
    </Layout>
  )
}

export default LayoutPasswordResetRequestPage
